import vietinbank_logo from '../assets/images/bank_small_logos/vietinbank.png';
import cake_logo from '../assets/images/bank_small_logos/cake.png';
import abbank_logo from '../assets/images/bank_small_logos/abbank.png';
import vib_logo from '../assets/images/bank_small_logos/vib.png';
import acb_logo from '../assets/images/bank_small_logos/acb.png';
import viettel_money_logo from '../assets/images/bank_small_logos/viettel-money.png';
import vnpt_money_logo from '../assets/images/bank_small_logos/vnpt-money.png';
import mb_logo from '../assets/images/bank_small_logos/mb.png';
import bidv_logo from '../assets/images/bank_small_logos/bidv.webp';
import keb_hana_hcm_logo from '../assets/images/bank_small_logos/kebhana-hcm.png';
import nonghuyp_logo from '../assets/images/bank_small_logos/nh-investment.png';
import shb_logo from '../assets/images/bank_small_logos/shb.webp';
import kien_long_logo from '../assets/images/bank_small_logos/kienlong.png';
import cb_bank_logo from '../assets/images/bank_small_logos/cbbank.webp';
import cimb_logo from '../assets/images/bank_small_logos/cimb.png';
import woorri_logo from '../assets/images/bank_small_logos/woorri.png';
import agribank_logo from '../assets/images/bank_small_logos/agribank.png';
import hlbank_logo from '../assets/images/bank_small_logos/hongleo.png';
import shinhan_logo from '../assets/images/bank_small_logos/shinhang.webp';
import sacombank_logo from '../assets/images/bank_small_logos/sacombank.png';
import msb_logo from '../assets/images/bank_small_logos/msb.png';
import coopbank_logo from '../assets/images/bank_small_logos/coopbank.png';
import kbhn_logo from '../assets/images/bank_small_logos/kbhn.png';
import pvcombank_logo from '../assets/images/bank_small_logos/pvcombank.png';
import ivb_logo from '../assets/images/bank_small_logos/ivb.png';
import ubo_logo from '../assets/images/bank_small_logos/uob.png';
import oceanbank_logo from '../assets/images/bank_small_logos/ocean.png';
import scb_logo from '../assets/images/bank_small_logos/scb.webp';
import ocb_logo from '../assets/images/bank_small_logos/ocb.webp';
import nam_a_bank_logo from '../assets/images/bank_small_logos/nam_a.png';
import umee_logo from '../assets/images/bank_small_logos/umee.png';
import ubank_logo from '../assets/images/bank_small_logos/ubank.webp';
import kbhcm_logo from '../assets/images/bank_small_logos/kbhn.png';
import saigonbank_logo from '../assets/images/bank_small_logos/saigonbank.png';
import dongabank_logo from '../assets/images/bank_small_logos/donga.png';
import ncb_logo from '../assets/images/bank_small_logos/ncb.png';
import hsbc_logo from '../assets/images/bank_small_logos/hsbc.png';
import seabank_logo from '../assets/images/bank_small_logos/seabank.png';
import techcombank_logo from '../assets/images/bank_small_logos/techcombank.png';
import mafc_logo from '../assets/images/bank_small_logos/mafc.png';
import scvn_logo from '../assets/images/bank_small_logos/standarchar.png';
import vietbank_logo from '../assets/images/bank_small_logos/vietbank.png';
import bvbank_timo_logo from '../assets/images/bank_small_logos/timo.webp';
import pbvn_logo from '../assets/images/bank_small_logos/public.png';
import pgbank_logo from '../assets/images/bank_small_logos/pgbank.png';
import ibkbank_logo from '../assets/images/bank_small_logos/ibk-hcm.png';
import vietabank_logo from '../assets/images/bank_small_logos/vieta.png';
import bacabank_logo from '../assets/images/bank_small_logos/bacabank.png';
import hdbank_logo from '../assets/images/bank_small_logos/hdbank.webp';
import citibank_logo from '../assets/images/bank_small_logos/citibank.png';
import dbs_logo from '../assets/images/bank_small_logos/dbs.png';
import vbsp_logo from '../assets/images/bank_small_logos/vbsp.webp';
import tpbank_logo from '../assets/images/bank_small_logos/tpbank.webp';
import liobank_logo from '../assets/images/bank_small_logos/mafc.png';
import kbank_logo from '../assets/images/bank_small_logos/kbank.png';
import eximbank_logo from '../assets/images/bank_small_logos/eximbank.png';
import gpbank_logo from '../assets/images/bank_small_logos/gpbank.webp';
import vrb_logo from '../assets/images/bank_small_logos/vrb.png';
import vpbank_logo from '../assets/images/bank_small_logos/vpbank.png';
import lpbank_logo from '../assets/images/bank_small_logos/lpbank.png';
import keb_hana_hn_logo from '../assets/images/bank_small_logos/kebhana-hcm.png';
import vietcombank_logo from '../assets/images/bank_small_logos/vcb.png';
import viet_capital_logo from '../assets/images/bank_small_logos/vietcapital.png';
import baoviet_logo from '../assets/images/bank_small_logos/baoviet.png';

export const BEARER = "Bearer ";
export const TOKEN_NAME = 'j88-billmaker';
export const J88_BILLMAKER_USER = 'j88-billmaker-user';
export const ACCESS_ADMIN_ACTION = 'access-admin';

export const banks = {
  vietinbank: {
    key: 'vietinbank',
    logo: vietinbank_logo,
    shortName: 'VIETINBANK',
    fullName: 'Ngân hàng Công Thương Việt Nam'
  },
  cake: {
    key: 'cake',
    logo: cake_logo,
    shortName: 'CAKE',
    fullName: 'Ngân hàng CAKE BY VPBANK'
  },
  abbank: {
    key: 'abbank',
    logo: abbank_logo,
    shortName: 'ABBANK',
    fullName: 'Ngân hàng An Bình'
  },
  vib: {
    key: 'vib',
    logo: vib_logo,
    shortName: 'VIB',
    fullName: 'Ngân hàng Quốc tế'
  },
  acb: {
    key: 'acb',
    logo: acb_logo,
    shortName: 'ACB',
    fullName: 'Ngân hàng Á Châu'
  },
  viettel_money: {
    key: 'viettel_money',
    logo: viettel_money_logo,
    shortName: 'VIETTEL MONEY',
    fullName: 'Viettel Money'
  },
  vnpt_money: {
    key: 'vnpt_money',
    logo: vnpt_money_logo,
    shortName: 'VNPT MONEY',
    fullName: 'VNPT Money'
  },
  mb: {
    key: 'mb',
    logo: mb_logo,
    shortName: 'MB',
    fullName: 'Ngân hàng Quân Đội'
  },
  bidv: {
    key: 'bidv',
    logo: bidv_logo,
    shortName: 'BIDV',
    fullName: 'Ngân hàng Đầu tư và phát triển Việt Nam'
  },
  keb_hana_hcm: {
    key: 'keb_hana_hcm',
    logo: keb_hana_hcm_logo,
    shortName: 'KEB HANA',
    fullName: 'Ngân hàng KEB HANA - CN TP Hồ Chí Minh'
  },
  nonghuyp: {
    key: 'nonghuyp',
    logo: nonghuyp_logo,
    shortName: 'NONGHUYP',
    fullName: 'Ngân hàng Nonghuyp - Chi nhánh Hà Nội'
  },
  shb: {
    key: 'shb',
    logo: shb_logo,
    shortName: 'SHB',
    fullName: 'Ngân hàng Sài Gòn Hà Nội'
  },
  kien_long: {
    key: 'kien_long',
    logo: kien_long_logo,
    shortName: 'KIEN LONG BANK',
    fullName: 'Ngân hàng Kiên Long'
  },
  cb_bank: {
    key: 'cb_bank',
    logo: cb_bank_logo,
    shortName: 'CBBANK',
    fullName: 'Ngân hàng TM TNHH MTV Xây Dựng Việt Nam'
  },
  cimb: {
    key: 'cimb',
    logo: cimb_logo,
    shortName: 'CIMB',
    fullName: 'Ngân hàng TNHH MTV CIMB Việt Nam'
  },
  woorri: {
    key: 'woorri',
    logo: woorri_logo,
    shortName: 'WOORI',
    fullName: 'Ngân hàng Woori Việt Nam'
  },
  agribank: {
    key: 'agribank',
    logo: agribank_logo,
    shortName: 'AGRIBANK',
    fullName: 'Ngân hàng Nông nghiệp và phát triển nông thôn Việt Nam'
  },
  hlbank: {
    key: 'hlbank',
    logo: hlbank_logo,
    shortName: 'HLBANK',
    fullName: 'Ngân hàng HongLeong Việt Nam'
  },
  shinhan: {
    key: 'shinhan',
    logo: shinhan_logo,
    shortName: 'SHINHAN',
    fullName: 'Ngân hàng Shinhan Bank Việt Nam'
  },
  sacombank: {
    key: 'sacombank',
    logo: sacombank_logo,
    shortName: 'SACOMBANK',
    fullName: 'Ngân hàng Sài Gòn thương tín'
  },
  msb: {
    key: 'msb',
    logo: msb_logo,
    shortName: 'MSB',
    fullName: 'Ngân hàng Hàng Hải'
  },
  coopbank: {
    key: 'coopbank',
    logo: coopbank_logo,
    shortName: 'CO-OPBANK',
    fullName: 'Ngân hàng Hợp tác xã Việt Nam'
  },
  kbhn: {
    key: 'kbhn',
    logo: kbhn_logo,
    shortName: 'KBHN',
    fullName: 'Ngân hàng Kookmin - CNHà Nội'
  },
  pvcombank: {
    key: 'pvcombank',
    logo: pvcombank_logo,
    shortName: 'PVCOMBANK',
    fullName: 'Ngân hàng Đại chúng'
  },
  ivb: {
    key: 'ivb',
    logo: ivb_logo,
    shortName: 'IVB',
    fullName: 'Ngân hàng TNHH Indovina'
  },
  ubo: {
    key: 'ubo',
    logo: ubo_logo,
    shortName: 'UOB Việt Nam',
    fullName: 'Ngân hàng TNHH MTV UOB Việt Nam'
  },
  oceanbank: {
    key: 'oceanbank',
    logo: oceanbank_logo,
    shortName: 'OCEANBANK',
    fullName: 'Ngân hàng TNHH MTV Đại Dương'
  },
  scb: {
    key: 'scb',
    logo: scb_logo,
    shortName: 'SCB',
    fullName: 'Ngân hàng Sài Gòn'
  },
  ocb: {
    key: 'ocb',
    logo: ocb_logo,
    shortName: 'OCB',
    fullName: 'Ngân hàng Phương Đông'
  },
  nam_a_bank: {
    key: 'nam_a_bank',
    logo: nam_a_bank_logo,
    shortName: 'NAM A BANK',
    fullName: 'Ngân hàng Nam Á'
  },
  umee: {
    key: 'umee',
    logo: umee_logo,
    shortName: 'UMEE',
    fullName: 'Ngân hàng số UMEE by Kienlongbank'
  },
  ubank: {
    key: 'ubank',
    logo: ubank_logo,
    shortName: 'UBANK',
    fullName: 'Ngân hàng UBANK BY VPBANK'
  },
  kbhcm: {
    key: 'kbhcm',
    logo: kbhcm_logo,
    shortName: 'KB HCM',
    fullName: 'Ngân hàng Ngân hàng Kookmin - CN TP Hồ Chí Minh'
  },
  saigonbank: {
    key: 'saigonbank',
    logo: saigonbank_logo,
    shortName: 'SAIGONBANK',
    fullName: 'Ngân hàng Sài Gòn Công Thương'
  },
  dongabank: {
    key: 'dongabank',
    logo: dongabank_logo,
    shortName: 'DONG A BANK',
    fullName: 'Ngân hàng Đông Á'
  },
  ncb: {
    key: 'ncb',
    logo: ncb_logo,
    shortName: 'NCB',
    fullName: 'Ngân hàng Quốc Dân'
  },
  hsbc: {
    key: 'hsbc',
    logo: hsbc_logo,
    shortName: 'HSBC',
    fullName: 'Ngân hàng TNHH MTV HSBC Việt Nam'
  },
  seabank: {
    key: 'seabank',
    logo: seabank_logo,
    shortName: 'SEABANK',
    fullName: 'Ngân hàng Đông Nam Á'
  },
  techcombank: {
    key: 'techcombank',
    logo: techcombank_logo,
    shortName: 'TECHCOMBANK',
    fullName: 'Ngân hàng Kỹ thương Việt Nam'
  },
  mafc: {
    key: 'mafc',
    logo: mafc_logo,
    shortName: 'MAFC',
    fullName: 'Công ty Tài chính TNHH MTV Mirae Asset Việt Nam'
  },
  scvn: {
    key: 'scvn',
    logo: scvn_logo,
    shortName: 'SCVN',
    fullName: 'Ngân hàng TNHH MTV Standard Chartered Việt Nam'
  },
  vietbank: {
    key: 'vietbank',
    logo: vietbank_logo,
    shortName: 'VIETBANK',
    fullName: 'Ngân hàng Việt Nam Thương Tín'
  },
  bvbank_timo: {
    key: 'bvbank_timo',
    logo: bvbank_timo_logo,
    shortName: 'BVBANK TIMO',
    fullName: 'Ngân hàng số Timo - Ngân hàng TMCP Bản Việt'
  },
  pbvn: {
    key: 'pbvn',
    logo: pbvn_logo,
    shortName: 'PBVN',
    fullName: 'Ngân hàng TNHH MTV Public Việt Nam'
  },
  pgbank: {
    key: 'pgbank',
    logo: pgbank_logo,
    shortName: 'PGBANK',
    fullName: 'Ngân hàng TMCP Thịnh vượng và Phát triển (PGBANK)'
  },
  ibkbank: {
    key: 'ibkbank',
    logo: ibkbank_logo,
    shortName: 'IBK BANK HN',
    fullName: 'Ngân hàng Công nghiệp Hàn Quốc - CN Hà Nội'
  },
  vietabank: {
    key: 'vietabank',
    logo: vietabank_logo,
    shortName: 'VIET A BANK',
    fullName: 'Ngân hàng Việt Á'
  },
  bacabank: {
    key: 'bacabank',
    logo: bacabank_logo,
    shortName: 'BAC A BANK',
    fullName: 'Ngân hàng Bắc Á'
  },
  hdbank: {
    key: 'hdbank',
    logo: hdbank_logo,
    shortName: 'HD BANK',
    fullName: 'Ngân hàng Phát triển TP.HCM'
  },
  citibank: {
    key: 'citibank',
    logo: citibank_logo,
    shortName: 'CITIBANK',
    fullName: 'Ngân hàng Citibank Việt Nam - CN hà Nội'
  },
  dbs: {
    key: 'dbs',
    logo: dbs_logo,
    shortName: 'DBS',
    fullName: 'Ngân hàng DBS - CN Hồ Chí Minh'
  },
  vbsp: {
    key: 'vbsp',
    logo: vbsp_logo,
    shortName: 'VBSP',
    fullName: 'Ngân hàng Chính sách Xã hội VBSP'
  },
  tpbank: {
    key: 'tpbank',
    logo: tpbank_logo,
    shortName: 'TPBANK',
    fullName: 'Ngân hàng Tiên phong'
  },
  liobank: {
    key: 'liobank',
    logo: liobank_logo,
    shortName: 'LIOBANK',
    fullName: 'Ngân hàng số Liobank - Ngân hàng TMCP Phương Đông'
  },
  kbank: {
    key: 'kbank',
    logo: kbank_logo,
    shortName: 'KBANK',
    fullName: 'Ngân hàng đại chúng TNHH KASIKORNBANK - CN TP Hồ Chí Minh'
  },
  eximbank: {
    key: 'eximbank',
    logo: eximbank_logo,
    shortName: 'EXIMBANK',
    fullName: 'Ngân hàng Xuất Nhập khẩu'
  },
  gpbank: {
    key: 'gpbank',
    logo: gpbank_logo,
    shortName: 'GPBANK',
    fullName: 'Ngân hàng Dầu khí toàn cầu'
  },
  vrb: {
    key: 'vrb',
    logo: vrb_logo,
    shortName: 'VRB',
    fullName: 'Ngân hàng Liên doanh Việt Nga'
  },
  vpbank: {
    key: 'vpbank',
    logo: vpbank_logo,
    shortName: 'VPBANK',
    fullName: 'Ngân hàng Việt Nam Thịnh Vượng'
  },
  lpbank: {
    key: 'lpbank',
    logo: lpbank_logo,
    shortName: 'LIEN VIET POST BANK',
    fullName: 'Ngân hàng Bưu điện Liên Việt'
  },
  keb_hana_hn: {
    key: 'keb_hana_hn',
    logo: keb_hana_hn_logo,
    shortName: 'KEB HANA',
    fullName: 'Ngân hàng KEB HANA - CN Hà Nội'
  },
  vietcombank: {
    key: 'vietcombank',
    logo: vietcombank_logo,
    shortName: 'Vietcombank',
    fullName: 'Ngân hàng TMCP Ngoại Thương Việt Nam'
  },
  viet_capital: {
    key: 'viet_capital',
    logo: viet_capital_logo,
    shortName: 'Viet Capital Bank',
    fullName: 'Ngân hàng Bản Việt'
  },
  baoviet: {
    key: 'baoviet',
    logo: baoviet_logo,
    shortName: 'BAO VIET BANK',
    fullName: 'Ngân hàng Bảo Việt'
  },
}