import { Link, Outlet } from 'react-router-dom';
import Menu from '../../../components/menu';
import Header from '../../../components/header';
import logo from '../../../assets/images/j88-logo.png';
import styles from './style.module.css';

export default function UserLayout() {
  return (
    <div className={"d-flex"}>
      <div className="col-2 h-100 px-3 pb-3">
        <div className="text-center">
          <Link to='/'>
            <img className="col-3 pt-3" src={logo} alt="logo" />
          </Link>
        </div>
        <Menu />
      </div>
      <div className="col-10 h-100">
        <Header />
        <div className={"p-3 " + styles.rightSide}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}