import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import AuthRoute from "./auth_route";
import AdminRoute from "./admin_route";
import Loading from "../components/loading";
import UserLayout from "../components/layouts/user_layout";
import AdminLayout from "../components/layouts/admin_layout";

const Dashboard = lazy(() => import("../pages/dashboard"));
const Transfers = lazy(() => import("../pages/transfers"));
const VietcombankTransfer = lazy(() => import("../pages/transfers/vietcombank"));
const TechcombankTransfer = lazy(() => import("../pages/transfers/techcombank"));
const BIDVTransfer = lazy(() => import("../pages/transfers/bidv"));
const SacombankTransfer = lazy(() => import("../pages/transfers/sacombank"));
const VietinbankTransfer = lazy(() => import("../pages/transfers/vietinbank"));
const MSBTransfer = lazy(() => import("../pages/transfers/msb"));
const VpbankTransfer = lazy(() => import("../pages/transfers/vpbank"));
const MomoTransfer = lazy(() => import("../pages/transfers/momo"));
const MBBankTransfer = lazy(() => import("../pages/transfers/mbbank"));
const AgribankTransfer = lazy(() => import("../pages/transfers/agribank"));
const TPBankTransfer = lazy(() => import("../pages/transfers/tpbank"));
const ACBTransfer = lazy(() => import("../pages/transfers/acb"));
const OCBTransfer = lazy(() => import("../pages/transfers/ocb"));
const DongABankTransfer = lazy(() => import("../pages/transfers/dongabank"));
const PVComBankTransfer = lazy(() => import("../pages/transfers/pvcombank"));
const NamABankTransfer = lazy(() => import("../pages/transfers/namabank"));

const Transactions = lazy(() => import("../pages/transactions"));
const VietcombankTransaction = lazy(() => import("../pages/transactions/vietcombank"));
const TechcombankTransaction = lazy(() => import("../pages/transactions/techcombank"));

const Balances = lazy(() => import("../pages/balances"));
const BIDVBalance = lazy(() => import("../pages/balances/bidv"));
const AgribankBalance = lazy(() => import("../pages/balances/agribank"));

const SMS = lazy(() => import("../pages/sms"));
const TPBankSMS = lazy(() => import("../pages/sms/tpbank"));

const WithdrawalRequest = lazy(() => import("../pages/withdrawal_request"));
const TransactionHistory = lazy(() => import("../pages/transaction_history"));
const HomeScreen = lazy(() => import("../pages/home_screen"));
const GameScreen = lazy(() => import("../pages/game_screen"));

const Admin = lazy(() => import("../pages/admin"));
const UserManagement = lazy(() => import("../pages/admin/user_management"));
const RoleManagement = lazy(() => import("../pages/admin/role_management"));
const Login = lazy(() => import("../pages/login"));
const ErrorPage = lazy(() => import("../pages/error"));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/">
            <Route index={true} element={
              <AuthRoute>
                <Dashboard />
              </AuthRoute>
            } />
          </Route>

          {/* Transfers */}
          <Route path="transfers">
            <Route index={true} element={
              <AuthRoute>
                <Transfers />
              </AuthRoute>
            } />
            <Route index={false} path="vietcombank" element={
              <AuthRoute>
                <VietcombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="techcombank" element={
              <AuthRoute>
                <TechcombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="bidv" element={
              <AuthRoute>
                <BIDVTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="sacombank" element={
              <AuthRoute>
                <SacombankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="vietinbank" element={
              <AuthRoute>
                <VietinbankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="msb" element={
              <AuthRoute>
                <MSBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="vpbank" element={
              <AuthRoute>
                <VpbankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="momo" element={
              <AuthRoute>
                <MomoTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="mbbank" element={
              <AuthRoute>
                <MBBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="agribank" element={
              <AuthRoute>
                <AgribankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="tpbank" element={
              <AuthRoute>
                <TPBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="acb" element={
              <AuthRoute>
                <ACBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="ocb" element={
              <AuthRoute>
                <OCBTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="dongabank" element={
              <AuthRoute>
                <DongABankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="pvcombank" element={
              <AuthRoute>
                <PVComBankTransfer />
              </AuthRoute>
            } />
            <Route index={false} path="namabank" element={
              <AuthRoute>
                <NamABankTransfer />
              </AuthRoute>
            } />
          </Route>

          {/* Transactions */}
          <Route path="transactions">
            <Route index={true} element={
              <AuthRoute>
                <Transactions />
              </AuthRoute>
            } />
            <Route index={false} path="vietcombank" element={
              <AuthRoute>
                <VietcombankTransaction />
              </AuthRoute>
            } />
            <Route index={false} path="techcombank" element={
              <AuthRoute>
                <TechcombankTransaction />
              </AuthRoute>
            } />
          </Route>

          {/* Balances */}
          <Route path="balances">
            <Route index={true} element={
              <AuthRoute>
                <Balances />
              </AuthRoute>
            } />
            <Route index={false} path="bidv" element={
              <AuthRoute>
                <BIDVBalance />
              </AuthRoute>
            } />
            <Route index={false} path="agribank" element={
              <AuthRoute>
                <AgribankBalance />
              </AuthRoute>
            } />
          </Route>

          {/* Balances */}
          <Route path="sms">
            <Route index={true} element={
              <AuthRoute>
                <SMS />
              </AuthRoute>
            } />
            <Route index={false} path="tpbank" element={
              <AuthRoute>
                <TPBankSMS />
              </AuthRoute>
            } />
          </Route>

          {/* Withdrawal Request */}
          <Route path="withdrawal-request">
            <Route index={true} element={
              <AuthRoute>
                <WithdrawalRequest />
              </AuthRoute>
            } />
          </Route>

          {/* Transaction History */}
          <Route path="transaction-history">
            <Route index={true} element={
              <AuthRoute>
                <TransactionHistory />
              </AuthRoute>
            } />
          </Route>

          {/* Home Screen */}
          <Route path="home-screen">
            <Route index={true} element={
              <AuthRoute>
                <HomeScreen />
              </AuthRoute>
            } />
          </Route>

          {/* Game Screen */}
          <Route path="game-screen">
            <Route index={true} element={
              <AuthRoute>
                <GameScreen />
              </AuthRoute>
            } />
          </Route>
        </Route>

        {/* ADMIN ROUTES */}
        <Route element={<AdminLayout />}>
          <Route path="admin">
            <Route index={true} element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            } />
            <Route path="user-management" element={
              <AdminRoute>
                <UserManagement />
              </AdminRoute>
            } />
            <Route path="role-management" element={
              <AdminRoute>
                <RoleManagement />
              </AdminRoute>
            } />
          </Route>
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}